// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import apisList from 'api_get'
const apis = apisList.apis[0] || apisList.apis[1]

const config = {
    /**
     * @description api请求基础路径
     */
    baseUrl: {
        dev: 'https://apitest858.taolu.fit/deseappV11', // 开发和测试环境
        prod: apis.api + '/deseappV11' // 线上环境
    },
    cdnUrl: 'https://routine-cdn-resource.oss-cn-hangzhou.aliyuncs.com/website/images/'
}

export default config
